import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { ReactQueryDevtools } from "react-query/devtools";

import 'styles/less/vars.less';

import "styles/css/index.css";
import "styles/css/custom_scrollbar.css";
import "styles/css/custom_antd.css";
import "styles/css/autocomplete.css"

import { MainLoading } from "components/common/main_loading";
import {PROD} from "config/config";

const Providers = lazy(() => import("context/providers_wrapper"));
const App = lazy(() => import("App"));

ReactDOM.render(
  <Suspense
    fallback={<MainLoading />}
  >
    <Providers>
      <App />
      {
        !PROD &&
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      }
    </Providers>
  </Suspense>,
  document.getElementById("root")
);
