// APP CONFIGURATION
export const ENABLE_USERS_EDIT = process.env.REACT_APP_ENABLE_USERS_EDIT === "true";
export const PROD = process.env.REACT_APP_PROD === "true";

// BACKEND ENDPOINTS
export const API_URL = new URL(process.env.REACT_APP_API_URL);
export const WS_URL = new URL(process.env.REACT_APP_WS_URL);

// ENVIRONMENT DEFINITION
export const DASH_PREFIX = process.env.REACT_APP_DASH_PREFIX;
export const SLASH_PREFIX = process.env.REACT_APP_SLASH_PREFIX;
export const METRICS_NAMESPACE = process.env.REACT_APP_METRICS_NAMESPACE;
export const ENV = process.env.REACT_APP_ENV;
export const REGION = process.env.REACT_APP_REGION;
export const VPC_PROD = process.env.REACT_APP_VPC_PROD;
export const VPC_MANAGEMENT = process.env.REACT_APP_VPC_MANAGEMENT;

// PROTOCOLS CONFIGURATION
export const CMSG = JSON.parse(process.env.REACT_APP_CMSG);
export const NICE_DCV = JSON.parse(process.env.REACT_APP_NICE_DCV);
export const PARSEC = JSON.parse(process.env.REACT_APP_PARSEC);

// COGNITO CONFIGURATION
export const COGNITO_IDENTITY_POOL_ID = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
export const COGNITO_WEB_CLIENT_ID = process.env.REACT_APP_COGNITO_WEB_CLIENT_ID;
export const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;
export const COGNITO_REDIRECT_URI = process.env.REACT_APP_COGNITO_REDIRECT_URI;
export const COGNITO_IDENTITY_PROVIDERS = process.env.REACT_APP_COGNITO_IDENTITY_PROVIDERS.split(";");
export const COGNITO_ADMIN_GROUPS = process.env.REACT_APP_COGNITO_ADMIN_GROUPS.split(";");
export const COGNITO_ARTIST_GROUPS = process.env.REACT_APP_COGNITO_ARTIST_GROUPS.split(";");
export const COGNITO_GROUP_CLAIM = process.env.REACT_APP_COGNITO_GROUP_CLAIM;
export const COGNITO_GROUP_REGEX = process.env.REACT_APP_COGNITO_GROUP_REGEX;
export const PATH_TO_USERID = process.env.REACT_APP_PATH_TO_USERID;

// AUTH COOKIES CONFIGURATION
export const USE_SECURE_COOKIE = process.env.REACT_APP_USE_SECURE_COOKIE;
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

// ROLES CONFIGURATION
export const ARTIST_ROLE_NAME = process.env.REACT_APP_ARTIST_ROLE_NAME;

// MULTI-REGION CONFIGURATION
export const EXTRA_REGIONS = JSON.parse(process.env.REACT_APP_EXTRA_REGIONS);
